import React from 'react';
import { Grid, Link, Paper, Typography } from "@material-ui/core";
import logo from './ENDOCLINIC_LOGO_Q-1.png';
import './help.css';

function App() {

    return (
        <Grid
            container
            direction="column"
            justify="center"
            alignItems="center"
            spacing={ 2 }
        >
            <Grid item style={ { display: "flex" } } justify={ 'center' } xs={ 12 }>
                <img style={ { width: '100%' } } src={ logo } alt='Logo'/>
            </Grid>
            <Grid item style={ { width: '100%' } }>
                <Grid
                    container
                    direction="row"
                    justify="center"
                    alignItems="center"
                    spacing={ 2 }
                >
                    <Grid item xs={ 12 } md={ 4 }>
                        <Paper className={ 'brand-box' }>
                            <Typography variant="body2">
                                Endoclinic
                            </Typography>
                            <Typography variant="body2">
                                Spółka z ograniczoną odpowiedzialnością s.k.
                            </Typography>
                            <Typography variant="body2">
                                ul. Chłodna 12, 30-424 Kraków
                            </Typography>
                            <Typography variant="body2">
                                REGON 12194272200025
                            </Typography>
                            <Typography variant="body2">
                                NIP 6793068851
                            </Typography>
                        </Paper>
                    </Grid>
                    <Grid item xs={ 12 } md={ 4 }>
                        <Paper className={ 'brand-box' }>
                            <Typography variant="body2">
                                Centrum Medyczne Endoclinic
                            </Typography>
                            <Typography variant="body2">
                                Pracownia endoskopii Kraków
                            </Typography>
                            <Typography variant="body2">
                                <Link href="https://goo.gl/maps/bXNf3GLmNViKDvcn6">
                                    ul. Josepha Conrada 79
                                </Link>
                            </Typography>
                            <Typography variant="body2">
                                31-357 Kraków
                            </Typography>
                            <Typography variant="body2">
                                <Link href="tel:+48-12-307-00-35">
                                    tel. 12 307 00 35
                                </Link>
                            </Typography>
                        </Paper>
                    </Grid>
                    <Grid item xs={ 12 } md={ 4 }>
                        <Paper className={ 'brand-box' }>
                            <Typography variant="body2">
                                Centrum Medyczne Endoclinic
                            </Typography>
                            <Typography variant="body2">
                                Pracownia endoskopii Tarnów
                            </Typography>
                            <Typography variant="body2">
                                <Link href="https://goo.gl/maps/WbPbzrVk1kmQnjCC6">
                                    ul. Polskiego Czerwonego Krzyża 26
                                </Link>
                            </Typography>
                            <Typography variant="body2">
                                33-100 Tarnów
                            </Typography>
                            <Typography variant="body2">
                                <Link href="tel:+48-14-307-00-33">
                                    tel. 14 307 00 33
                                </Link>
                            </Typography>
                        </Paper>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}

export default App;
